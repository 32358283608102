// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.5);
  overflow: hidden;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
}

[dir="ltr"] .ribbon {
  left: -3.5em;
  transform: rotate(-45deg);
}

[dir="rtl"] .ribbon {
  right: -3.5em;
  transform: rotate(45deg);
}

.ribbon a {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}

/* ==========================================================================
Navbar styles
========================================================================== */
.navbar-brand {
  overflow: hidden;
}

.jh-navbar .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-version {
  font-size: 0.65em;
  color: #bbb;
}

[dir="ltr"] .navbar-version {
  padding: 0 0 0 10px;
}

[dir="rtl"] .navbar-version {
  padding: 0 10px 0 0;
}

.brand-logo:hover {
  text-decoration: none;
}

.brand-logo .brand-icon {
  height: 35px;
  width: auto;
  display: inline-block;
}

.brand-logo .brand-icon img {
  width: 35px;
}

.brand-title {
  font-size: 1.25rem;
  color: #fff;
}

[dir="ltr"] .brand-title {
  margin-left: 0.25rem;
}

[dir="rtl"] .brand-title {
  margin-right: 0.25rem;
}

.brand-title:hover {
  color: #cccccc;
  text-decoration: none;
}

.loading-bar {
  height: 3px;
  background-color: #009cd8;
  position: absolute;
  top: 0px;
  z-index: 1031;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/header/header.scss"],"names":[],"mappings":"AAMA;;4EAAA;AAGA;EACE,sCAAA;EAOA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;EACA,aAAA;AALF;;AAVA;EAEE,YAAA;EAKA,yBAAA;AAGF;;AAVA;EAEE,aAAA;EAKA,wBAAA;AAGF;;AAME;EACE,WAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;EACA,oBAAA;AAJJ;;AAQA;;4EAAA;AAGA;EACE,gBAAA;AALF;;AAQA;EACE,gBAAA;EACA,eAAA;AALF;;AAQA;EACE,iBAAA;EACA,WAjDuB;AA6CzB;;AAEA;EAGE,mBAAA;AALF;;AAEA;EAGE,mBAAA;AALF;;AASE;EACE,qBAAA;AANJ;;AAQE;EACE,YAAA;EACA,WAAA;EACA,qBAAA;AANJ;;AAOI;EACE,WAAA;AALN;;AAUA;EACE,kBAAA;EAEA,WAvEa;AAgEf;;AAIA;EAEE,oBAAA;AANF;;AAIA;EAEE,qBAAA;AANF;;AAQE;EACE,cAvEiB;EAwEjB,qBAAA;AANJ;;AAUA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;AAPF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
