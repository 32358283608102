import dayjs from 'dayjs';
import { IExpenseClaim } from 'app/shared/model/expense-claim.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IExpenseItem {
  id?: number;
  expDate?: dayjs.Dayjs;
  description?: string | null;
  receiptNo?: string | null;
  amount?: number | null;
  exchangeRate?: number | null;
  attachmentPath?: string | null;
  expenseClaim?: IExpenseClaim;
  expenseType?: IHrCode;
  currecny?: IHrCode;
  status?: IHrCode;
}

export const defaultValue: Readonly<IExpenseItem> = {};
