import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { IEmpProfile, defaultValue } from 'app/shared/model/emp-profile.model';
import { EntityState, IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<IEmpProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/emp-profiles';

// Actions
/* eslint no-console: off */
export const getEntities = createAsyncThunk(
  'empProfile/fetch_entity_list',
  async ({ query, page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${query ? `${query}&` : ''}${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IEmpProfile[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'empProfile/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEmpProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntityByEmployee = createAsyncThunk(
  'empProfile/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/my/${id}`;
    return axios.get<IEmpProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const countEntity = createAsyncThunk(
  'empProfile/fetch_entity',
  async () => {
    const requestUrl = `${apiUrl}/count`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'empProfile/create_entity',
  async (entity: IEmpProfile, thunkAPI) => {
    const result = await axios.post<IEmpProfile>(apiUrl, cleanEntity(entity), {  headers: { 'Content-Type': 'multipart/form-data' },});
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const uploadFile = createAsyncThunk(
  'api/upload',
  async (formData: FormData, thunkAPI) => {
    try {
      const result = await axios.post('api/upload', formData, {  headers: { 'Content-Type': 'multipart/form-data', }  });
      // thunkAPI.dispatch(getEntities({}));
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(serializeAxiosError(error));
    }
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'empProfile/update_entity',
  async (entity: IEmpProfile, thunkAPI) => {
    // const formData = new FormData();
    // if (entity.profilePic) {
    //   formData.append('profilePic', entity.profilePic);
    //   entity.profilePic = null;
    // }
    // formData.append('empProfileDTO', new Blob([JSON.stringify(entity)], { type: 'application/json' }));
    // console.log('--- pic: ', formData.get('profilePic'));
    // console.log('--- DTO: ', formData.get('empProfileDTO'));
    // console.log('--- ID: ', formData.get('empProfileDTO.id'));
    // console.log('--- FormData: ', formData);
    const result = await axios.put<IEmpProfile>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
    // try {
    //   const formData = new FormData();
    //   // Assuming `profilePic` is a property on `entity` that holds the file
    //   if (entity.profilePic) {
    //     formData.append('profilePic', entity.profilePic);
    //     // entity.profilePic = null;
    //   }
    //   formData.append('empProfileDTO', new Blob([JSON.stringify(entity)], { type: 'application/json' }));
    //   console.log('--- FormData: ', formData);
    //   const result = await axios.put<IEmpProfile>(`${apiUrl}/${entity.id}`, formData);
    //   return result;
    // } catch (error) {
    //   return thunkAPI.rejectWithValue(serializeAxiosError(error));
    // }
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'empProfile/partial_update_entity',
  async (entity: IEmpProfile, thunkAPI) => {
    const result = await axios.patch<IEmpProfile>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'empProfile/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEmpProfile>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const EmpProfileSlice = createEntitySlice({
  name: 'empProfile',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = EmpProfileSlice.actions;

// Reducer
export default EmpProfileSlice.reducer;
