import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset, updateEntity } from './allowance-item.reducer';

export const AllowanceItemUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const allowanceItemEntity = useAppSelector(state => state.allowanceItem.entity);
  const loading = useAppSelector(state => state.allowanceItem.loading);
  const updating = useAppSelector(state => state.allowanceItem.updating);
  const updateSuccess = useAppSelector(state => state.allowanceItem.updateSuccess);

  /* eslint no-console: off */
  const handleClose = () => {
    // localStorage.getItem('LAST_PAGE')!==localStorage.getItem('CUR_PAGE')? '' : navigate(localStorage.getItem('LAST_PAGE'));
    navigate('/allowance-item' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    if (!hrCodes && hrCodes.lenght===0) {
      dispatch(getHrCodes({}));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.curVal !== undefined && typeof values.curVal !== 'number') {
      values.curVal = Number(values.curVal);
    }
    if (values.nextVal !== undefined && typeof values.nextVal !== 'number') {
      values.nextVal = Number(values.nextVal);
    }

    const entity = {
      ...allowanceItemEntity,
      ...values,
      code: hrCodes.find(it => it.id.toString() === values.code.toString()),
      category: hrCodes.find(it => it.id.toString() === values.category.toString()),
      type: hrCodes.find(it => it.id.toString() === values.type.toString()),
      currency: hrCodes.find(it => it.id.toString() === values.currency.toString()),
      status: isNew? hrCodes.find(e=>e.codeType==='ALS' && e.codeName==='Pending') : hrCodes.find(it => (it.id.toString() === values.status.toString())),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...allowanceItemEntity,
          code: allowanceItemEntity?.code?.id,
          category: allowanceItemEntity?.category?.id,
          type: allowanceItemEntity?.type?.id,
          currency: allowanceItemEntity?.currency?.id,
          status: allowanceItemEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5 id="hrmsApp.allowanceItem.home.createOrEditLabel" data-cy="AllowanceItemCreateUpdateHeading" className="text-center fw-bold text-primary">
            <Translate contentKey="hrmsApp.allowanceItem.home.createOrEditLabel">Create or edit a AllowanceItem</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center m-2 text-primary fw-bold">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="allowance-item-id"
                  label={translate('global.field.id')+'*'}
                  className="row d-flex"
                  labelClass="col-md-4"
                  inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="allowance-item-code"
                name="code"
                data-cy="code"
                label={translate('hrmsApp.allowanceItem.code')+'*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
              <option value="" key="0" />
              {hrCodes?
                hrCodes.filter(outerEntity => outerEntity.codeType === 'ALI' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                  </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-category"
                name="category"
                data-cy="category"
                label={translate('hrmsApp.allowanceItem.category')+'*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'ALC' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-type"
                name="type"
                data-cy="type"
                label={translate('hrmsApp.allowanceItem.type')+'*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'ALT' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                id="allowance-item-currency"
                name="currency"
                data-cy="currency"
                label={translate('hrmsApp.allowanceItem.currency')+'*'}
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-md"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {hrCodes?
                    hrCodes.filter(outerEntity => outerEntity.codeType === 'CUR' && outerEntity.id%100>0).sort((a, b) => a.id - b.id).map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.codeName} {otherEntity.codeDescription? ` - ${otherEntity.codeDescription}` : ''} {otherEntity.parent ? `- [${otherEntity.parent.codeName}]` : ''}
                      </option>
                  ))
                : null}
              </ValidatedField>
              <ValidatedField
                label={translate('hrmsApp.allowanceItem.curVal')+'*'}
                id="allowance-item-curVal"
                name="curVal"
                data-cy="curVal"
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.allowanceItem.startDate')+'*'}
                id="allowance-item-startDate"
                name="startDate"
                data-cy="startDate"
                className="row d-flex"
                labelClass="col-md-4"
                inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {!isNew ? (
                  <ValidatedField
                    label={translate('hrmsApp.allowanceItem.nextVal')}
                    id="allowance-item-nextVal"
                    name="nextVal"
                    data-cy="nextVal"
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="text"
                  />
              ) : null }
              {!isNew ? (
                  <ValidatedField
                    label={translate('hrmsApp.allowanceItem.nextStartDate')}
                    id="allowance-item-nextStartDate"
                    name="nextStartDate"
                    data-cy="nextStartDate"
                    className="row d-flex"
                    labelClass="col-md-4"
                    inputClass="col-md-8 custom-input flex-grow-1 form-control-sm"
                    type="date"
                  />
              ) : null }
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/allowance-item" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AllowanceItemUpdate;
