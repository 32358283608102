import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { ITaskAssignment } from 'app/shared/model/task-assignment.model';

export interface ITimeSheet {
  id?: number;
  dated?: dayjs.Dayjs;
  hours?: number;
  activities?: string;
  remarks?: string | null;
  employee?: IEmployee;
  taskType?: IHrCode;
  assignment?: ITaskAssignment | null;
  internalTask?: IHrCode | null;
  status?: IHrCode | null;
}

export const defaultValue: Readonly<ITimeSheet> = {};
