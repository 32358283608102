import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './approval-limit.reducer';

export const ApprovalLimitDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const approvalLimitEntity = useAppSelector(state => state.approvalLimit.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <h5 data-cy="approvalLimitDetailsHeading" className="text-center fw-bold text-primary">
          <Translate contentKey="hrmsApp.approvalLimit.detail.title">ApprovalLimit</Translate>
        </h5>
        <dl className="jh-entity-details bg-white">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{approvalLimitEntity.id}</dd>
          <dt>
            <span id="roleName">
              <Translate contentKey="hrmsApp.approvalLimit.roleName">Role</Translate>
            </span>
          </dt>
          <dd>{approvalLimitEntity.roleName}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="hrmsApp.approvalLimit.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{approvalLimitEntity.amount}</dd>
          <dt>
            <span id="startedOn">
              <Translate contentKey="hrmsApp.approvalLimit.startedOn">Started On</Translate>
            </span>
          </dt>
          <dd>
            {approvalLimitEntity.startedOn ? (
              <TextFormat value={approvalLimitEntity.startedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endedOn">
              <Translate contentKey="hrmsApp.approvalLimit.endedOn">Ended On</Translate>
            </span>
          </dt>
          <dd>
            {approvalLimitEntity.endedOn ? (
              <TextFormat value={approvalLimitEntity.endedOn} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="nextAmount">
              <Translate contentKey="hrmsApp.approvalLimit.nextAmount">Next Amount</Translate>
            </span>
          </dt>
          <dd>{approvalLimitEntity.nextAmount}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="hrmsApp.approvalLimit.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{approvalLimitEntity.remarks}</dd>
          <dt>
            <Translate contentKey="hrmsApp.approvalLimit.status">Status</Translate>
          </dt>
          <dd>{approvalLimitEntity.status ? approvalLimitEntity.status.codeName : ''}</dd>
        </dl>
        <Button tag={Link} to="/approval-limit" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/approval-limit/${approvalLimitEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApprovalLimitDetail;
