import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import { getEntities as getBusinessClients } from 'app/entities/business-client/business-client.reducer';
import { getEntities as getBusinessProjects } from 'app/entities/business-project/business-project.reducer';
import { getEntities as getHolidays } from 'app/entities/country-holiday/country-holiday.reducer';
import { getEntities as getWorkdays } from 'app/entities/country-workday/country-workday.reducer';
import { getEntities as getInternalTasks } from 'app/entities/internal-task/internal-task.reducer';
import { getEntities as getProjectTasks } from 'app/entities/project-task/project-task.reducer';
import { getEntities as getAssignments } from 'app/entities/task-assignment/task-assignment.reducer';
import { getEntities } from './time-sheet.reducer';

import { addDays, getWeek, startOfWeek } from 'date-fns';
import React from 'react';
import TimeSheetMatrix from './time-sheet-matrix';

export const TimeSheet = () => {
  const dispatch = useAppDispatch();

  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const projectTasks = useAppSelector(state=> state.projectTask.entities);
  const taskAssignments = useAppSelector(state=>state.taskAssignment.entities);
  const contracts = useAppSelector(state=>state.contract.entities);
  const account = useAppSelector(state => state.authentication.account);
  const hrCodes = useAppSelector(state=>state.hrCode.entities);

  /* eslint no-console: off */
  const getFirstDayOfMonth = (): Date => {
    const today = new Date();
    const currentMonth = today.getMonth(); // January is 0, December is 11
    const currentYear = today.getFullYear();
    if (today.getDate() < 11) {
      // If current date is less than 11, set to the first day of the previous month
      const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      return new Date(previousMonthYear, previousMonth, 1);
    } else {
      // Otherwise, set to the first day of the current month
      return new Date(currentYear, currentMonth, 1);
    }
  };

  const [activeStatusId, setActiveStatusId] = useState<number>(null);
  const [curMonday, setCurMonday] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const pastDays = 15 * 7; // 15 Weeks
  const pastMonday = addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), - pastDays);
  const [empId, setEmpId] = useState<number>(null);

  useEffect(() => {
    dispatch(getInternalTasks({}));
    dispatch(getWorkdays({}));
  }, []);

  useEffect(() => {
    setActiveStatusId(hrCodes.find(e => (e.codeName==='Active' && e.codeType==='WKS'))?.id);
  }, [hrCodes]);

  useEffect(() => {
    if (account && activeStatusId) {
      setEmpId(account?.employeeRef);
      if (taskAssignments.length===0) {
        // dispatch(getAssignments({ query: `employeeId.equals=${account.employeeRef}&statusId.equals=${activeStatusId}`}));
        dispatch(getAssignments({ query: `employeeId.equals=${account?.employeeRef}`}));
        dispatch(getEntities({ query: `employeeId.equals=${account?.employeeRef}`})); // TimeSheet for that employee
      }
    }
  }, [account, activeStatusId]);

  useEffect(() => {
    if (taskAssignments.length>0) {
      const taskIds = taskAssignments.map(e => e.projectTask.id);
      const queryString = taskIds.map(value => `id.in=${value}`).join('&');
      dispatch(getProjectTasks({ query: queryString }));
    }
  }, [taskAssignments]);

  useEffect(() => {
    if (projectTasks.length>0) {
      // const vMinStartDate = projectTasks?.reduce((minDate, task) => {
      //   const taskDate = new Date(task.startDate);
      //   return taskDate < minDate ? taskDate : minDate;
      // }, new Date(projectTasks[0].startDate));
      // setMinStartDate(vMinStartDate);
      // console.log('----- MinimumDate: ', vMinStartDate);
      const projectIds = projectTasks.map(e => e.businessProject.id);
      const queryString = projectIds.map(value => `id.in=${value}`).join('&');
      dispatch(getBusinessProjects({ query: queryString }));
    }
  }, [projectTasks]);

  useEffect(() => {
    if (businessProjects.length>0) {
      const ids = businessProjects.map(e => e.mainClient.id);
      const queryString = ids.map(value => `id.in=${value}`).join('&');
      dispatch(getBusinessClients({ query: queryString }));
    }
  }, [businessProjects]);

  useEffect(() => {
    if (contracts.length>0) {
      const empContract = contracts.filter(e => e.employee.id===empId && e.contractStatus.id===hrCodes.find(t=>t.codeType==='CTS' && t.codeName==='Active')?.id);
      const countryId = hrCodes.find(e => e.id===hrCodes.find(h => h.id.toString()===empContract?.map(c => c.location.id)?.toString())?.parent?.id)?.id;
      if (countryId!==null && countryId!==undefined) {
        dispatch(getHolidays({ query: `countryId.equals=${countryId}` }));
      }
    }
  }, [contracts]);

  const getPreviousWeek = (date: Date): Date => {
    const prevWeek = new Date(date);
    prevWeek.setDate(date.getDate() - 7);
    return prevWeek;
  };

  const getNextWeek = (date: Date): Date => {
    const nextWeek = new Date(date);
    nextWeek.setDate(date.getDate() + 7);
    return nextWeek;
  };

  const handleFirstWeek = () => {
    setCurMonday(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), - pastDays));
  };

  const handlePreviousWeek = () => {
    setCurMonday(prevDate => getPreviousWeek(prevDate));
  };

  const handleNextWeek = () => {
    setCurMonday(prevDate => getNextWeek(prevDate));
  };

  const handleLastWeek = () => {
    setCurMonday(startOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const isPreviousDisabled = curMonday <= pastMonday;
  const isNextDisabled = curMonday >= startOfWeek(new Date(), { weekStartsOn: 1 });

  return (
    <div>
      <h6 id="time-sheet-heading" data-cy="TimeSheetHeading" className="text-center text-primary fw-bold p-1">Time Sheet (<span className="text-danger">Week-{getWeek(curMonday, { weekStartsOn: 1 })}</span>)
        <br/>(<span className="text-warning" style={{ fontSize: 'smaller' }}>{curMonday.toDateString()} - {addDays(curMonday, 6).toDateString()}</span>)
      </h6>
      <div className="d-flex justify-content-center">
      </div>
      <div className="d-flex justify-content-center mb-2" style={{ fontSize: 'smaller' }}>
        <Button className="me-2" color="primary" onClick={handleFirstWeek} disabled={isPreviousDisabled}>
          <FontAwesomeIcon icon={/* faArrowLeft */ faAngleDoubleLeft} />{' '}
        </Button>
        <Button className="me-2" color="primary" onClick={handlePreviousWeek} disabled={isPreviousDisabled}>
          <FontAwesomeIcon icon={faAngleLeft} />{' '}
        </Button>
        <Button className="me-2" color="primary" onClick={handleNextWeek} disabled={isNextDisabled}>
          <FontAwesomeIcon icon={faAngleRight} />{' '}
        </Button>
        <Button className="me-2" color="primary" onClick={handleLastWeek} disabled={isNextDisabled}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />{' '}
        </Button>
      </div>
      {<TimeSheetMatrix empId={account.employeeRef} weekStartDate={curMonday} /> }
    </div>
  );
};

export default TimeSheet;
