import dayjs from 'dayjs';
import { IContract } from 'app/shared/model/contract.model';
import { IBusinessProject } from 'app/shared/model/business-project.model';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IExpenseClaim {
  id?: number;
  remarks?: string | null;
  dated?: dayjs.Dayjs;
  contract?: IContract;
  businessProject?: IBusinessProject;
  status?: IHrCode;
}

export const defaultValue: Readonly<IExpenseClaim> = {};
