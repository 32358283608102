import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getBusinessProjects } from 'app/entities/business-project/business-project.reducer';
import { getEntities as getContracts } from 'app/entities/contract/contract.reducer';
import { getEntities as getHrCodes } from 'app/entities/hr-code/hr-code.reducer';
import { createEntity, getEntity, reset, updateEntity } from './leaves-record.reducer';

export const LeavesRecordUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const contracts = useAppSelector(state => state.contract.entities);
  const businessProjects = useAppSelector(state => state.businessProject.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const leavesRecordEntity = useAppSelector(state => state.leavesRecord.entity);
  const loading = useAppSelector(state => state.leavesRecord.loading);
  const updating = useAppSelector(state => state.leavesRecord.updating);
  const updateSuccess = useAppSelector(state => state.leavesRecord.updateSuccess);

  const handleClose = () => {
    navigate('/leaves-record');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getContracts({}));
    dispatch(getBusinessProjects({}));
    dispatch(getHrCodes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dayCount !== undefined && typeof values.dayCount !== 'number') {
      values.dayCount = Number(values.dayCount);
    }

    const entity = {
      ...leavesRecordEntity,
      ...values,
      contract: contracts.find(it => it.id.toString() === values.contract?.toString()),
      businessProject: businessProjects.find(it => it.id.toString() === values.businessProject?.toString()),
      leaveItem: hrCodes.find(it => it.id.toString() === values.leaveItem?.toString()),
      status: hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...leavesRecordEntity,
          contract: leavesRecordEntity?.contract?.id,
          businessProject: leavesRecordEntity?.businessProject?.id,
          leaveItem: leavesRecordEntity?.leaveItem?.id,
          status: leavesRecordEntity?.status?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h5
            id="hrmsApp.leavesRecord.home.createOrEditLabel"
            data-cy="LeavesRecordCreateUpdateHeading"
            className="text-center fw-bold text-primary"
          >
            <Translate contentKey="hrmsApp.leavesRecord.home.createOrEditLabel">Create or edit a LeavesRecord</Translate>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center text-primary">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="leaves-record-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.fromDate')}
                id="leaves-record-fromDate"
                name="fromDate"
                data-cy="fromDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.toDate')}
                id="leaves-record-toDate"
                name="toDate"
                data-cy="toDate"
                type="date"
              />
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.dayCount')}
                id="leaves-record-dayCount"
                name="dayCount"
                data-cy="dayCount"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.remarks')}
                id="leaves-record-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
              />
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.dated')}
                id="leaves-record-dated"
                name="dated"
                data-cy="dated"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('hrmsApp.leavesRecord.attachmentPath')}
                id="leaves-record-attachmentPath"
                name="attachmentPath"
                data-cy="attachmentPath"
                type="text"
              />
              <ValidatedField
                id="leaves-record-contract"
                name="contract"
                data-cy="contract"
                label={translate('hrmsApp.leavesRecord.contract')}
                type="select"
                required
              >
                <option value="" key="0" />
                {contracts
                  ? contracts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="leaves-record-businessProject"
                name="businessProject"
                data-cy="businessProject"
                label={translate('hrmsApp.leavesRecord.businessProject')}
                type="select"
                required
              >
                <option value="" key="0" />
                {businessProjects
                  ? businessProjects.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="leaves-record-leaveItem"
                name="leaveItem"
                data-cy="leaveItem"
                label={translate('hrmsApp.leavesRecord.leaveItem')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="leaves-record-status"
                name="status"
                data-cy="status"
                label={translate('hrmsApp.leavesRecord.status')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hrCodes
                  ? hrCodes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/leaves-record" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LeavesRecordUpdate;
