import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './approval-limit.reducer';

export const ApprovalLimit = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const approvalLimitList = useAppSelector(state => state.approvalLimit.entities);
  const loading = useAppSelector(state => state.approvalLimit.loading);
  const totalItems = useAppSelector(state => state.approvalLimit.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h5 id="approval-limit-heading" data-cy="ApprovalLimitHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.approvalLimit.home.title">Approval Limits</Translate>
        <div className="d-flex justify-content-end">
          <Button className="btn-sm me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="hrmsApp.approvalLimit.home.refreshListLabel">Refresh</Translate>
          </Button>
          <Link to="/approval-limit/new" className="btn btn-sm btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="hrmsApp.approvalLimit.home.createLabel">Create new Approval Limit</Translate>
          </Link>
        </div>
      </h5>
      <div className="table-responsive">
        {hrCodes && hrCodes.length > 0 && approvalLimitList && approvalLimitList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr className="table-info">
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="hrmsApp.approvalLimit.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('roleName')}>
                  <Translate contentKey="hrmsApp.approvalLimit.roleName">Role</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('roleName')} />
                </th>
                <th className="hand" onClick={sort('amount')}>
                  <Translate contentKey="hrmsApp.approvalLimit.amount">Amount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('amount')} />
                </th>
                <th className="hand" onClick={sort('startedOn')}>
                  <Translate contentKey="hrmsApp.approvalLimit.startedOn">Started On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startedOn')} />
                </th>
                <th className="hand" onClick={sort('endedOn')}>
                  <Translate contentKey="hrmsApp.approvalLimit.endedOn">Ended On</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('endedOn')} />
                </th>
                <th className="hand" onClick={sort('nextAmount')}>
                  <Translate contentKey="hrmsApp.approvalLimit.nextAmount">Next Amount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('nextAmount')} />
                </th>
                <th className="hand" onClick={sort('remarks')}>
                  <Translate contentKey="hrmsApp.approvalLimit.remarks">Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('remarks')} />
                </th>
                <th>
                  <Translate contentKey="hrmsApp.approvalLimit.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {approvalLimitList.map((approvalLimit, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/approval-limit/${approvalLimit.id}`} color="link" size="sm">
                      {approvalLimit.id}
                    </Button>
                  </td>
                  <td>{approvalLimit.roleName}</td>
                  <td>{approvalLimit.amount}</td>
                  <td>
                    {approvalLimit.startedOn ? (
                      <TextFormat type="date" value={approvalLimit.startedOn} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {approvalLimit.endedOn ? <TextFormat type="date" value={approvalLimit.endedOn} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{approvalLimit.nextAmount}</td>
                  <td>{approvalLimit.remarks}</td>
                  <td>
                    {approvalLimit.status ? hrCodes.find(e=>e.id===approvalLimit.status.id)?.codeName : ''}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/approval-limit/${approvalLimit.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`} color="primary" size="sm" data-cy="entityEditButton" > 
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button onClick={() => (window.location.href = `/approval-limit/${approvalLimit.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`) } color="danger" size="sm" data-cy="entityDeleteButton" >
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="hrmsApp.approvalLimit.home.notFound">No Approval Limits found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={approvalLimitList && approvalLimitList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ApprovalLimit;
