import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { MaritalStatus } from 'app/shared/model/enumerations/marital-status.model';

export interface IEmpProfile {
  id?: number;
  maritalStatus?: keyof typeof MaritalStatus | null;
  cnic?: number;
  cnicIssueDate?: dayjs.Dayjs | null;
  cnicExpiryDate?: dayjs.Dayjs | null;
  cnicUrl?: string | null;
  passportNo?: string | null;
  passportIssueDate?: dayjs.Dayjs | null;
  passportExpiryDate?: dayjs.Dayjs | null;
  passportUrl?: string | null;
  personalMobile?: number;
  personalLandline?: number | null;
  personalEmailAddress?: string | null;
  curAddressLine1?: string;
  curAddressLine2?: string | null;
  permAddressLine1?: string | null;
  permAddressLine2?: string | null;
  profilePicUrl?: string | null;
  gender?: IHrCode;
  employee?: IEmployee;
}

export const defaultValue: Readonly<IEmpProfile> = {};
