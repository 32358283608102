import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './pending-approval.reducer';

export const PendingApprovalStats = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const pendingApprovalList = useAppSelector(state => state.pendingApproval.entities);
  const loading = useAppSelector(state => state.pendingApproval.loading);

  /* eslint-disable no-console */
  useEffect(() => {
    dispatch(getEntities({}));
    // dispatch(getEntities({query: `initiatorId.equals=${account?.employeeRef}&approverId.equals=${account?.employeeRef}`}));
  }, []);

  let pendingTotal=10;
  let initByElse=10;
  let initByYou=0;

  useEffect(() => {
    pendingTotal=pendingApprovalList.length;
    initByElse=pendingApprovalList.filter(e=>e.approver.id===account?.employeeRef).length;
    initByYou=pendingApprovalList.filter(e=>e.initiator.id===account?.employeeRef).length;

    const initByYouDiv = document.getElementById('init-by-you');
    const initByElseDiv = document.getElementById('init-by-else');

    if (initByYouDiv && initByElseDiv) {
      initByYouDiv.innerHTML=initByYou.toString();
      initByElseDiv.innerHTML=initByElse.toString();
    }
  }, [pendingApprovalList]);

  return (
    (pendingApprovalList ? (
      // <div className={`row m-0 ${pendingApprovalList && pendingApprovalList.length > 0 ? 'border border-primary rounded' : ''}`}>
      <div className={`border border-gray rounded bg-light text-center`}> <h5 className='text-center'>Pending Approvals</h5>
          {/* <div className='col-md-4 bg-light border border-light p-2'> <h5 className='text-center'>Pending Approvals</h5> */}
              {/* <div className='row m-0 bg-white text-primary text-center fw-bold '> */}
                  <div className='text-white bg-primary px-2 py-1'>Initiated By You: <span id='init-by-you' className='bg-white text-primary px-2'>{initByYou}</span></div>
                  <div className='text-white bg-info px-2 py-1'>Pending For You: <span id='init-by-else' className='bg-white text-primary px-2'>{initByElse}</span></div>
              {/* </div> */}
              {/* <div className="progress">
                <div className="progress-bar" role="progressbar" style={{width: '15%' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar bg-success" role="progressbar" style={{width: '30%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar bg-info" role="progressbar" style={{width: '20%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div> */}

              {/* <div className='text-center text-white bg-warning fw-bold rounded'>Approvals Pending For You</div> */}
          {/* </div>
          <div className='col-md-4 border border-light p-0'> <h5 className='text-center'>Pending Approvals</h5>
              <Progress multi className='text-white m-0 p-0 md' barAriaValueText='Label' >
                  <Progress bar color="success" className='text-white fw-bold' value={20} >By You: {initByYou}</Progress>
                  <Progress bar color="warning" className='text-white fw-bold' value={20}>For You: {initByElse}</Progress>
              </Progress>
              {/* <div className='text-center  text-white bg-info fw-bold'>Approvals Initiated By You</div> */}
          {/* </div>
          <div className='col-md-4'>
              <div className='text-center  text-white bg-success fw-bold'>Your Request Status</div>
          </div> */} 
          {/* {approvalLogs && 
            <div className='w-50 bg-primary'>This is pendingApprovals</div>
          }
          {pendingApprovals &&
            <div className='w50 bg-light'>This is approvalLog</div>
          } */}
          {/* <div className="col-6">Half width on all screen sizes</div>
          <div className="col-md-4">One-third width on medium and larger screen sizes</div>
          <div className="col-md-auto">Width based on content on large and larger screen sizes</div>
          <div className="col-xl-3">One-fourth width on extra large and larger screen sizes</div> */}
      </div>
        ) : ' '
    )
  )};

export default PendingApprovalStats;