import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getContracts } from 'app/entities/contract/contract.reducer';
import React, { useEffect, useState } from 'react';
import { Alert, Popover, PopoverBody, PopoverHeader } from 'reactstrap';

export const Genealogy  = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const employees = useAppSelector(state => state.employee.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);

  const [treeWidth, setTreeWidth] = useState(3000);
  const [parentWidth, setParentWidth] = useState(3000);
  const [treeDone, setTreeDone] = useState(false);
  const jsxElements = [];
  let lvl =0;
  const self = employees.filter(e=>e.id.toString() === account?.employeeRef.toString())[0]; // account?.employeeRef.toString())[0]; // '283710000')[0]; //  283710000  512710047  273910026 354010001 274410004

  const [popoverOpen, setPopoverOpen] = useState<{ [key: string]: boolean }>({});

  const togglePopover = (id: number) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  /* eslint no-console: off */
  useEffect(() => {
    dispatch(getContracts({}));
  }, []);

  const popoverBody = (emp, cat) => {
    const cont = contracts.find(e=>e.employee.id.toString()===emp.id.toString() && e.contractStatus.id===(hrCodes.find(c=>c.codeType==='CTS' && c.codeName==='Active')));
    console.log('------ Cont: ', cont?.length, ' - ', emp.firstName, ' ', emp.lastName);
    return (
      <>
        {contracts && cat==='manager' && (
          <>
            {emp.user?.login} <br/>
          </>
        )}
        {contracts && (cat==='leaf' || cat==='node') && (
          <>
            {emp.user?.login} <br/> {cont?.length>0? cont?.designation?.id: null}
            <table className='table-responsive p-0'> 
              <tbody>
                  <tr><td className='fw-bold'>QLID:</td><td>{emp.qlid}</td></tr>
                  <tr><td className='fw-bold'>Type:</td><td>{hrCodes.find(e=>e.id===emp.employeeType.id)?.codeName}</td></tr>
                  <tr><td className='fw-bold'>Status:</td><td>{hrCodes.find(e=>e.id===emp.employeeStatus.id)?.codeName}</td></tr>
                  <tr><td className='fw-bold'>Joinning:</td><td>{emp.joiningDate}</td></tr>
              </tbody>
            </table>
          </>
        )}
        {contracts && cat==='self' && (
          <>
            {emp.user?.login} <br/> {cont?.length>0? cont?.designation?.id: null}
            <table className='table-responsive p-0'> 
              <tbody>
                  <tr><td className='fw-bold'>QLID:</td><td>{emp.qlid}</td></tr>
                  <tr><td className='fw-bold'>Type:</td><td>{hrCodes.find(e=>e.id===emp.employeeType.id)?.codeName}</td></tr>
                  <tr><td className='fw-bold'>Status:</td><td>{hrCodes.find(e=>e.id===emp.employeeStatus.id)?.codeName}</td></tr>
                  <tr><td className='fw-bold'>Joinning:</td><td>{emp.joiningDate}</td></tr>
              </tbody>
            </table>
          </>
        )}
      </>
    )
  }

  const treeNode = (emp, cat) => {
    cat = (emp.id === self.id)? 'self' : cat; 
    return (
      <div className='d-flex justify-content-center'>
        <div id={`Popover${emp.id}`} className={`px-1 border border-primary rounded ${cat === 'node' ? 'bg-secondary' : cat === 'manager' ? 'bg-danger' : cat==='self' ? 'bg-success' : 'bg-light'} ${cat==='leaf' ? 'text-primary' : 'text-white'}`} 
              onMouseEnter={() => togglePopover(emp.id)} onMouseLeave={() => togglePopover(emp.id)}> {emp.qlid.substring(0,2)}
          <Popover placement={`${cat==='leaf' ? 'bottom' : cat==='self' ? 'left' : 'right'}`} isOpen={popoverOpen[emp.id]} target={`Popover${emp.id}`} toggle={() => togglePopover(emp.qlid)}>
            <PopoverHeader>{emp.firstName} {emp.lastName}</PopoverHeader>
            <PopoverBody>{popoverBody(emp, cat)}</PopoverBody>
          </Popover>
            {/* <Card id="Popover1" onMouseEnter={togglePopover} onMouseLeave={togglePopover}> {data.qlid}
              <Popover placement="top" isOpen={popoverOpen} target="Popover1" toggle={togglePopover}>
                <PopoverHeader>Popover Title</PopoverHeader>
                <PopoverBody>This is the content of the popover.</PopoverBody>
              </Popover>
            </Card> */}
        </div>
      </div> 
    );
  }

  const renderTree = () => {
    const jsx = upTree(self, subTree(self));
    jsxElements.push(<ul id='tree-root' key={'tree'} className={`${parentWidth > treeWidth ? 'd-flex justify-content-center' : ''}`} style={{ width: `${parentWidth}px`}}>{jsx}</ul>);
    resetTreeWidth();
    return jsxElements;
  }

  function subTree(me) {
    const reportees = employees.filter(e=>e.reportingLine?.id === me.id);
    const sTree =[];
    if (reportees.length >0 ) {
      const repTree = [];
      reportees.forEach(reportee=> {
        repTree.push(subTree(reportee));
      });
      const node = treeNode (me, 'node');
      sTree.push(<li key={me.id} id={me.reportingLine===null? 'root-node' : me.id}> {node} <ul key={`${me.id}-childs`}>{repTree}</ul></li>);
      lvl++;
    } else {
      const leaf = treeNode (me, 'leaf');
      sTree.push(<li key={me.id}> {leaf} </li>);
    }
    return sTree;
  }

  function upTree (me, childs) {
    const locTree = [];
    if (me.reportingLine !== null ) {
      me = employees.filter(e=> e.id === me.reportingLine.id)[0];
      const tmpTree = [];
      const manager = treeNode(me, 'manager');
      tmpTree.push(<li key={me.id} id={me.reportingLine===null? 'root-node' : me.id} >{manager}<ul key={`${me.id}-childs`}> {childs} </ul></li>);
      locTree.push(upTree(me, tmpTree));
    } else {
      locTree.push(childs);
    }
    return locTree;
  };

  function resetTreeWidth ()  {
    const rootDiv = document.getElementById('root-node');
    if (rootDiv && !treeDone) {
      const rootWidth = rootDiv.offsetWidth+2; // Added 2px to adjust border
      const parentDiv = document.getElementById('org-tree');
      const pWidth = parentDiv? ((parentDiv.offsetWidth-20) < rootWidth ? parentDiv.offsetWidth-2 : parentDiv.offsetWidth-20) : 0; // -2 to accomodate border
      rootDiv.style.width = rootWidth + 'px';
      setTreeWidth(rootWidth);
      setParentWidth(pWidth);
      setTreeDone(true);
      console.log('----- RootWidth:', rootWidth, 'ParentWidth:', pWidth, '-', parentWidth);
    }
  }

  return (
    <div className='border border-gray rounded mt-4'>
      <h5 className="text-center text-primary bg-light fw-bold p-2 m-0 ">Reporting Line </h5>
      <div id='org-tree' className='org-tree p-0' >
        { (hrCodes && hrCodes.length > 0 && employees && employees.length > 0 && contracts && contracts.length > 0) ? ( 
            <>
            {renderTree()}
            {/* {jsxElements} */}
            </>
          ) : (
            <Alert color="warning">Data not found</Alert>
          )
        }
      </div>
    </div>
  );
};

export default Genealogy;
