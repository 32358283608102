import dayjs from 'dayjs';
import { IHrCode } from 'app/shared/model/hr-code.model';

export interface IApprovalLimit {
  id?: number;
  roleName?: string;
  amount?: number;
  startedOn?: dayjs.Dayjs;
  endedOn?: dayjs.Dayjs | null;
  nextAmount?: number;
  remarks?: string | null;
  status?: IHrCode;
}

export const defaultValue: Readonly<IApprovalLimit> = {};
