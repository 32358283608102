import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, getSortState, isNumber, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import { IContract } from 'app/shared/model/contract.model';
import { IEmployee } from 'app/shared/model/employee.model';
import dayjs from 'dayjs';
import { getEntities as getBusinessProjects } from '../business-project/business-project.reducer';
import { getEntities as getLeavesPlans } from '../leaves-plan/leaves-plan.reducer';
import { createEntity, getEntities as getLeavesRecords } from './leaves-record.reducer';

interface LeaveInfo {
  leaveId: number;
  planNameId: number;
  itemNameId: number;
  codeType: string;
  codeName: string;
  total: number;
  availed: number;
}
export const LeavesRecord = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const leavesRecords = useAppSelector(state => state.leavesRecord.entities);
  const leavesPlans = useAppSelector(state => state.leavesPlan.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const hrCodes = useAppSelector(state => state.hrCode.entities);
  const account = useAppSelector(state => state.authentication.account);
  const loading = useAppSelector(state => state.leavesRecord.loading);
  const [curEmployee, setCurEmployee] = useState<IEmployee>(null);
  const [curContract, setCurContract] = useState<IContract>(null);
  const [curEmpLeaves, setCurEmpLeaves] = useState<LeaveInfo[]>([]);
  const [curLeaveItem, setCurLeaveItem] = useState<LeaveInfo>(null);
  const [done, setDone] = useState(false);

  const businessProjects = useAppSelector(state => state.businessProject.entities);

  const [isNew, setIsNew] = useState(false);
  // const [selectedOption, setSelectedOption] = useState<number>();
  // const [selectedEntity, setSelectedEntity] = useState(countryHolidayList.find(e=>e.id===-1));
  const [selectedEntity, setSelectedEntity] = useState(leavesRecords.find(e => e.id === -1));
  const updateSuccess = useAppSelector(state => state.leavesRecord.updateSuccess);

  /* eslint no-console: off */
  // useEffect(() => {
  //   if (account?.employeeRef !== null && account?.employeeRef!==undefined) {
  //     dispatch(getContracts({ query: `employeeId.equals=${account?.employeeRef}`} ));
  //   }
  // }, [account]);

  useEffect(() => {
    if (
      account?.employeeRef !== null &&
      account?.employeeRef !== undefined &&
      contracts.length > 0 &&
      hrCodes.length > 0 &&
      employees.length > 0
    ) {
      const ctsActiveId = hrCodes.find(e => e.codeType === 'CTS' && e.codeName === 'Active')?.id;
      const employee = employees.find(e => e.id === account?.employeeRef);
      setCurEmployee(employee);
      const contract = contracts.find(e => e.employee.id === account?.employeeRef && e.contractStatus?.id === ctsActiveId);
      setCurContract(contract);
      dispatch(getLeavesPlans({ query: `planNameId.equals=${contract?.leavesPlan?.id}` }));
      dispatch(getLeavesRecords({ query: `contractId.equals=${contract?.id}` }));
      dispatch(getBusinessProjects({}));
    }
  }, [account, contracts, employees, hrCodes]);

  useEffect(() => {
    if (curEmployee !== null && curEmployee !== undefined && leavesPlans.length > 0 && leavesRecords) {
      const yearStart = dayjs().startOf('year');
      const currentYear = dayjs().year();
      const curDate = dayjs();
      const joining = dayjs(curEmployee.joiningDate);
      const lessDays = joining.isAfter(yearStart) ? joining.diff(yearStart, 'day') + 1 : 0; // Added 1 to include the joining day
      console.log('=== Joining: ', joining.format('YYYY-MM-DD'), ' LessDays: ', lessDays);
      const lvInfos: LeaveInfo[] = [];
      leavesPlans.forEach(plan => {
        const leaveItem = hrCodes.find(e => e.id === plan.itemName.id);
        const alsStatusId = hrCodes.find(e => e.codeType === 'ALS' && e.codeName === 'Active')?.id;
        const filteredData = leavesRecords.filter(
          e =>
            e.leaveItem.id === plan.item.id &&
            e.startDate.year() === currentYear &&
            e.endDate.year() === currentYear &&
            e.status.id === hrCodes.find(i => i.codeType === 'ALS' && i.codeName === 'Active')?.id,
        );
        const availedSoFar = filteredData.reduce((sum, item) => sum + item.qty, 0);
        lvInfos.push({
          leaveId: plan.id,
          planNameId: plan.planName.id,
          itemNameId: plan.itemName.id,
          total: lessDays === 0 ? plan.qty : (lessDays * plan.qty) / 365,
          availed: availedSoFar,
          codeType: leaveItem?.codeType,
          codeName: leaveItem?.codeName,
        });
      });
      if (lvInfos.length > 0) {
        setCurEmpLeaves(lvInfos);
        setDone(true);
      }
    }
  }, [curEmployee, leavesPlans, leavesRecords]);

  useEffect(() => {
    if (curEmpLeaves.length > 0) {
      console.log('====== Leave Record: ', curEmpLeaves.length, ' : ', curEmpLeaves);
    }
  }, [curEmpLeaves]);

  useEffect(() => {
    if (curLeaveItem !== null) {
      console.log('====== Leave Item: ', curLeaveItem);
    }
  }, [curLeaveItem]);

  // useEffect(() => {
  //   if (updateSuccess) {
  //     handleClose();
  //   }
  // }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dayCount !== undefined && typeof values.dayCount !== 'number') {
      values.dayCount = Number(values.dayCount);
    }

    const entity = {
      ...selectedEntity,
      ...values,
      contract: contracts.find(it => it.id.toString() === values.contract?.toString()),
      businessProject: businessProjects.find(it => it.id.toString() === values.businessProject?.toString()),
      leaveItem: hrCodes.find(it => it.id.toString() === values.leaveItem?.toString()),
      status: hrCodes.find(it => it.id.toString() === values.status?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...selectedEntity,
          contract: curContract?.id,
          businessProject: selectedEntity?.businessProject?.id,
          leaveItem: curLeaveItem?.itemNameId,
          status: selectedEntity?.status?.id,
        };

  const applyLeave = leaveItem => {
    setCurLeaveItem(leaveItem);
    setIsNew(true);
  };

  return (
    <div>
      <h5 id="leaves-record-heading" data-cy="LeavesRecordHeading" className="text-center text-primary fw-bold p-1">
        <Translate contentKey="hrmsApp.leavesRecord.home.title">Leaves Records</Translate>
      </h5>
      {done && curEmpLeaves.length > 0 ? (
        <>
          <div className="container d-flex justify-content-center">
            {curEmpLeaves.map((leave, index) => (
              <div key={index} className={`m-2 border border-gray rounded bg-light text-center`}>
                {' '}
                <h5 className="text-center">{leave.codeName}</h5>
                <div className="text-white bg-warning px-2 py-1">
                  Availed:{' '}
                  <span id="init-by-else" className="bg-white text-primary px-2">
                    {leave.availed}
                  </span>
                </div>
                <div className="text-white bg-success px-2 py-1">
                  Balance:{' '}
                  <span id="init-by-you" className="bg-white text-primary px-2">
                    {leave.total - leave.availed}
                  </span>
                </div>
                <div className="text-white bg-white px-2 py-1">
                  <Button className="me-2" color="primary" onClick={() => applyLeave(leave)} disabled={isNew}>
                    <FontAwesomeIcon icon="plus" spin={loading} />{' '}
                    {/* <Translate contentKey="hrmsApp.leavesRecord.home.refreshListLabel">Refresh List</Translate> */}
                  </Button>
                </div>
              </div>
              //   {lvRecord.map((leave, index) => (
              //     <div key={index} className='flex-grow-1'>
              //       <p className='fw-bold'>
              //         {leave.codeName}: <span className="bg-warning text-white p-1">{leave.availed} / {leave.total - leave.availed}</span> <br />
              //         <Progress className="my-2" multi>
              //           <Progress bar color="info" value={(leave.availed * 100 / leave.total)}>Availed: {leave.availed}</Progress>
              //           <Progress bar color="warning" value={(leave.total - leave.availed)*100/leave.total}>Balance: {leave.total - leave.availed} </Progress>
              //           {/* <Progress bar color="success" value="30">Wow!</Progress> */}
              //           {/* <Progress bar color="info" value="25">Cool</Progress> */}
              //           {/* <Progress bar color="warning" value="20">20%</Progress> */}
              //           {/* <Progress bar color="danger" value="5">!!</Progress> */}
              //         </Progress>
              //       </p>
              //     </div>
              //   ))}
              // </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            {isNew ? (
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                {!isNew ? (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="leaves-record-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField
                  id="leaves-record-businessProject"
                  name="businessProject"
                  data-cy="businessProject"
                  label={translate('hrmsApp.leavesRecord.businessProject')}
                  type="select"
                  required
                >
                  <option value="" key="0" />
                  {businessProjects
                    ? businessProjects.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.projectName}
                        </option>
                      ))
                    : null}
                </ValidatedField>
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.fromDate')}
                  id="leaves-record-fromDate"
                  name="fromDate"
                  data-cy="fromDate"
                  type="date"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.toDate')}
                  id="leaves-record-toDate"
                  name="toDate"
                  data-cy="toDate"
                  type="date"
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.dayCount')}
                  id="leaves-record-dayCount"
                  name="dayCount"
                  data-cy="dayCount"
                  type="text"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                    validate: v => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label={translate('hrmsApp.leavesRecord.remarks')}
                  id="leaves-record-remarks"
                  name="remarks"
                  data-cy="remarks"
                  type="text"
                />
                {/* <ValidatedField
                  label={translate('hrmsApp.leavesRecord.dated')}
                  id="leaves-record-dated"
                  name="dated"
                  data-cy="dated"
                  type="date"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                /> */}
                {/* <ValidatedField
                  label={translate('hrmsApp.leavesRecord.attachmentPath')}
                  id="leaves-record-attachmentPath"
                  name="attachmentPath"
                  data-cy="attachmentPath"
                  type="text"
                /> */}
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => setIsNew(false)} replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Cancel</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" /*disabled={updating}*/>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            ) : null}
          </div>
        </>
      ) : (
        'No data found'
      )}
    </div>
  );
};

export default LeavesRecord;
